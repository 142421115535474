import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledSection = styled.section`
  background: black;
  #logo {
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
  }
  .introSection {
    transform: translateX(-80px);
    width: 100%;
    position: relative;
    padding: 100px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .gatsby-image-wrapper {
      flex: 0 0 25%;
      margin-right: 75px;
    }
    .introText {
      flex: 0 0 55%;
      font-family: 'Oswald';

      .pre-h1 {
        color: #009bce;
        margin-bottom: 0;
        font-size: calc(16px + 26 * ((100vw - 1000px) / 920));
        letter-spacing: 6px;
        text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px, #141a1f 0px 0px 0.5em;
      }
      h1 {
        text-transform: uppercase;
        font-size: calc(30px + 40 * ((100vw - 1000px) / 920));
        font-weight: 400;
        line-height: 1.15;
        margin: 0;
        letter-spacing: 2.5px;
        text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px, #141a1f 0px 0px 0.5em;
      }
      .post-h1 {
        color: #009bce;
        margin-top: 8px;
        font-size: calc(16px + 26 * ((100vw - 1000px) / 920));
        letter-spacing: 1.6px;
        text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px, #141a1f 0px 0px 0.5em;
      }
      @media (max-width: 1200px) {
        h1 {
          font-size: 38px;
        }
        .pre-h1,
        .post-h1 {
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: 900px) {
    > .gatsby-image-wrapper {
      display: none;
    }
    .introSection {
      flex: 0 0 100%;
      flex-wrap: wrap;
      position: relative;
      transform: none;
      flex-direction: column-reverse;
      .introText {
        padding: 0 30px;
      }
      .gatsby-image-wrapper {
        flex: 0 0 100%;
        max-width: 452px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  .section-bg {
    min-width: 1900px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .featuresSection {
    position: relative;
    padding: 50px 30px;
    z-index: 5;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1180px;
    box-sizing: border-box;
    h3 {
      font-weight: 400;
      font-size: calc(16px + 40 * ((100vw - 1000px) / 920));
      text-align: center;
      margin: 0 0 1.5em 0;
      letter-spacing: 1.4px;
      /* transition: all 200ms linear; */
      @media (max-width: 1400px) {
        font-size: 32px;
      }
    }
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .bullet {
      display: flex;
      align-items: flex-start;
      margin-left: 1em;
      margin-bottom: 25px;
      width: 100%;
      max-width: 540px;

      svg {
        transform: translateY(-3px);
      }
      p {
        max-width: 25em;
        font-family: 'Roboto';
        font-weight: 300;
        letter-spacing: 1.3px;
        font-size: 1.25rem;
        line-height: 1.55;
        margin: 0 auto;
        transition: all 200ms linear;
        @media (max-width: 600px) {
          font-size: 1rem;
        }
      }
    }
  }
  .cta-wrap {
    margin-top: 25px;
    text-align: center;
    font-family: 'Oswald';
    p {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1.17rem;
    }
  }
  .vennSection {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .gatsby-image-wrapper {
      flex: 0 0 50%;
      margin-left: 5%;
      max-width: 720px;
      width: 100%;
    }
    .copy {
      color: black;
      flex: 0 0 50%;
      max-width: 390px;
      font-family: 'Roboto';
      font-weight: 300;
      letter-spacing: 1.3px;
      font-size: 1.25rem;
      line-height: 1.55;
      margin: 0 auto;
      transition: all 200ms linear;
      padding-left: 30px;
      box-sizing: border-box;
    }
    @media (max-width: 880px) {
      flex-direction: column;
      .copy {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 30px;
      }
      .gatsby-image-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
      }
    }
  }
  .footerSection {
    position: relative;
    overflow: hidden;
    .gatsby-image-wrapper {
      min-width: 1740px;
      left: calc(50vw - 870px);
      max-width: 1910px;
    }
    .footerCopy {
      position: relative;
      width: 380px;
      padding: 180px 30px;
      margin: 0 auto;
      padding-left: 390px;
    }
    @media (min-width: 1845px) {
      .footerCopy {
        padding-left: 570px;
      }
    }
    @media (max-width: 800px) {
      .gatsby-image-wrapper {
        left: 0;
      }
      .footerCopy {
        width: 75%;
        margin-left: 0;
        padding: 180px 30px 180px;
        box-sizing: border-box;
      }
    }
    @media (max-width: 600px) {
      .footerCopy {
        width: 100%;
      }
    }
    h3 {
      font-family: 'Oswald';
      font-size: 1.9rem;
      line-height: 1.6;
      margin-bottom: 1.125rem;
      margin-top: 0;
      letter-spacing: 1.4px;
      color: #009bce;
      font-weight: 400;
      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }
    p {
      font-family: 'Roboto';
      font-weight: 300;
      letter-spacing: 1.3px;
      font-size: 1.25rem;
      line-height: 1.9;
      margin: 0 auto 20px;
      transition: all 200ms linear;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  li {
    flex: 0 0 calc(33% - 50px);
    margin-left: 25px;
    margin-top: 25px;
  }
`;

const Bullet = (props) => (
  <div className="bullet">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.6 343.5" width="18" height="36">
      <path
        fill="#ffffff"
        d="M31.7,239.4l0-135.2l117.1-67.6l117,67.6l0,135.2l-117.1,67.6L31.7,239.4z M148.8,0L0,85.9v171.7l148.8,85.9
                        l140.9-81.3l7.9-4.6l0-171.8L148.8,0z"
      />
    </svg>
    <p>{props.children}</p>
  </div>
);

// ($filter: wordpress__POSTFilterInput = { status: { eq: "publish" } })
const DgLanding = ({ data, pageContext }) => {
  const images = useStaticQuery(
    graphql`
      query {
        ogimg: file(relativePath: { eq: "og-img/secret-formula.jpg" }) {
          publicURL
        }
        headerbg: file(relativePath: { eq: "dg-header-bg.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 90)
          }
        }
        ebook: file(relativePath: { eq: "ebook-five-true-stories.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 452, quality: 90)
          }
        }
        img3: file(relativePath: { eq: "ebook-blue-inky-bg.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1910, quality: 90)
          }
        }
        img4: file(relativePath: { eq: "ebook-venn-chart.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 791, quality: 90)
          }
        }
        footer: file(relativePath: { eq: "ebook-footer-bg.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1910, quality: 90)
          }
        }
      }
    `
  );

  return (
    <Layout
      showNav={false}
      minimalFooter={true}
      title="Elements of Integrated Content Strategies That Drive Results"
      desc="Find out how audience and market research informs holistic content marketing efforts, which are powered by Owned, Earned, Shared, and Paid media tactics."
      ogUrl="https://goelement.com/secret-formula-digital-marketing-success/"
      ogImg={'https://goelement.com' + images.ogimg.publicURL}
    >
      <StyledSection className="section">
        <Link to="/">
          <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.6 343.5" width="50" height="100">
            <path
              className="st0"
              fill="#0095c8"
              d="M31.7,239.4l0-135.2l117.1-67.6l117,67.6l0,135.2l-117.1,67.6L31.7,239.4z M148.8,0L0,85.9v171.7l148.8,85.9
                        l140.9-81.3l7.9-4.6l0-171.8L148.8,0z"
            />
          </svg>
        </Link>
        <GatsbyImage
          image={images.headerbg.childImageSharp.gatsbyImageData}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          alt="Background"
        />
        <div className="introSection">
          <GatsbyImage image={images.ebook.childImageSharp.gatsbyImageData} alt="E-Book" />
          <div className="introText">
            <p className="pre-h1">E-BOOK</p>
            <h1>DISCOVER THE ELEMENTS OF&nbsp;INTEGRATED CONTENT STRATEGIES&nbsp;THAT DRIVE RESULTS</h1>
            <p className="post-h1">
              Still trying to crack the digital marketing code?
              <br />
              Why do some companies seem to have it all figured out?
            </p>
          </div>
        </div>
        <section style={{ position: 'relative', overflow: 'hidden' }}>
          <GatsbyImage
            image={images.img3.childImageSharp.gatsbyImageData}
            alt="background 2"
            // style={{ minWidth: '1910px', position: 'absolute', width: '100%', height: '100%' }}
            className="section-bg"
          />
          <div className="featuresSection">
            <h3>
              In our exclusive e-book, <i>The Formula for Modern Marketing Success</i>, you’ll discover:
            </h3>
            <div className="list">
              <Bullet>How a residential water treatment manufacturer earned top Google search rankings</Bullet>
              <Bullet>How a growing credit union improved brand awareness with community relations</Bullet>
              <Bullet>How a health insurance carrier reached its target audience with a unique content brand</Bullet>
              <Bullet>How a food safety company used controversial content to generate qualified leads</Bullet>
              <Bullet>
                How a B2B content marketing plan helped attract Fortune 500 prospects and shortened the sales cycle
              </Bullet>
            </div>
            <div className="cta-wrap">
              <a className="cta" target="_blank" href="/assets/pdf/Formula-for-Modern-Marketing-Success.pdf">
                Download the E-Book Now
              </a>
              <p>(It’s free. You’ve got nothing to lose. We don’t even want your email.)</p>
            </div>
          </div>
        </section>
        <div className="vennSection">
          <div className="copy">
            <p>
              <strong>Element</strong> is pulling back the curtain and revealing how we partner with companies in a
              variety of industries to develop effective integrated marketing strategies for the digital age.
            </p>
            <p>
              Find out how audience and market research informs holistic content marketing efforts, which are powered by{' '}
              <strong>Owned, Earned, Shared,</strong> and <strong>Paid</strong> media tactics.
            </p>
          </div>
          <GatsbyImage image={images.img4.childImageSharp.gatsbyImageData} alt="circles" />
        </div>
        <section className="footerSection">
          <GatsbyImage
            image={images.footer.childImageSharp.gatsbyImageData}
            alt="footer background"
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          />
          <div className="footerCopy">
            <h3>Want to know more?</h3>
            <p>See what our talented Elementals have to say about the latest industry trends.</p>
            <Link to="/blog/" className="cta">
              Visit Our Blog
            </Link>
          </div>
        </section>
      </StyledSection>
    </Layout>
  );
};

export default DgLanding;

//Query?  We don't need no stinking query!
//export const pageQuery = graphql`
//`;
